// src/AuthContext.js

import { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import URL from "../../URL";
import { jwtDecode } from "jwt-decode"; // Ensure jwtDecode is imported correctly

const AuthContext = createContext();

const base64UrlDecode = (base64Url) => {
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonStr = atob(base64); // Use `atob` for browser-compatible decoding
  return JSON.parse(jsonStr);
};

const AuthProvider = ({ children }) => {
  // Check if token is expired
  const isTokenExpired = () => {
    const token = localStorage.getItem("access_token");
    if (!token) return true;

    const tokenParts = token.split(".");
    if (tokenParts.length !== 3) return true; // Invalid token format

    const payload = base64UrlDecode(tokenParts[1]);

    if (!payload.exp) return false; // Token doesn't have an expiration claim

    const currentTime = Math.floor(Date.now() / 1000);
    return payload.exp < currentTime;
  };

  // Decode user from token
  const user = () => {
    const token = localStorage.getItem("access_token");
    return token ? jwtDecode(token) : null;
  };

  // Initial auth state
  const [authState, setAuthState] = useState({
    isAuthenticated: !isTokenExpired(),
    user: user(),
  });

  // Login function
  const login = (token = null) => {
    setAuthState({
      isAuthenticated: true,
      user: token
        ? jwtDecode(token)
        : jwtDecode(localStorage.getItem("access_token")),
    });
  };

  // Logout function
  const logout = () => {
    localStorage.clear();
    setAuthState({
      isAuthenticated: false,
      user: null,
    });
  };

  // Check if the user has access to a resource
  const HasResource = (resourceName) => {
    return authState?.user?.roles?.hasOwnProperty(resourceName) || false;
  };

  // Check if the user has a specific permission for a resource
  const HasPermission = (resourceName, permission) => {
    if (HasResource(resourceName)) {
      return (
        authState?.user?.roles[resourceName]?.includes(permission) || false
      );
    }
    return false;
  };

  // Function to refresh the access token
  const refreshAccessToken = async () => {
    try {
      const refreshToken = localStorage.getItem("refresh_token");
      if (!refreshToken) {
        logout(); // Ensure user is logged out if no refresh token
        return null;
      }

      const response = await axios.post(
        `${URL}api/jwt/refresh`,
        {},
        {
          headers: { Authorization: `Bearer ${refreshToken}` },
        }
      );

      const newAccessToken = response.data.access_token;
      localStorage.setItem("access_token", newAccessToken);
      login(newAccessToken); // Update state with new token
      return newAccessToken;
    } catch (error) {
      console.error("Token refresh failed", error);
      logout(); // Ensure user is logged out if refresh fails
      return null;
    }
  };

  // Add axios interceptor to refresh token when access token expires
  useEffect(() => {
    const responseInterceptor = axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;

        // If 401 (unauthorized) and the token has not already been retried
        if (error.response?.status === 401) {
          originalRequest._retry = true;

          try {
            const refreshedToken = await refreshAccessToken();
            if (!refreshedToken) {
              logout();
              return Promise.reject(error);
            }

            // Update original request with the new token
            originalRequest.headers.Authorization = `Bearer ${refreshedToken}`;
            return axios(originalRequest); // Retry original request with new token
          } catch (refreshError) {
            logout(); // Ensure user is logged out on refresh error
            return Promise.reject(refreshError);
          }
        }

        return Promise.reject(error); // Other errors
      }
    );

    return () => {
      axios.interceptors.response.eject(responseInterceptor); // Cleanup interceptor on unmount
    };
  }, []);

  const contextValue = {
    authState,
    login,
    logout,
    refreshAccessToken,
    isTokenExpired,
    HasResource,
    HasPermission,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export { AuthProvider, useAuth };
