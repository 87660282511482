import { useEffect, useState, useRef } from "react";
import io from "socket.io-client";
import { FiSend, FiTrash2 } from "react-icons/fi";
import { useAuth } from "../auth/AuthContext";
import URL from "../../URL"; // Backend base URL

export default function SystemChat() {
  const SERVER_URL = URL;
  const { authState } = useAuth();
  const { user } = authState;

  const [selectedUser, setSelectedUser] = useState(null);
  const [messages, setMessages] = useState({});
  const [input, setInput] = useState("");
  const [unread, setUnread] = useState({});
  const [allUsers, setAllUsers] = useState([]);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [typingUsers, setTypingUsers] = useState({});

  const messagesEndRef = useRef(null);
  const socket = useRef(null);
  const typingTimeout = useRef(null);

  // Sound setup
  const notificationSoundRef = useRef(null);
  useEffect(() => {
    notificationSoundRef.current = new Audio("/sounds/message.mp3");
    notificationSoundRef.current.volume = 0.5;
  }, []);

  // Socket connection
  useEffect(() => {
    if (!user) return;

    socket.current = io(SERVER_URL, {
      transports: ["websocket"],
      query: { user_id: user.id },
    });

    socket.current.on("connect", () => {
      socket.current.emit("join_room", { room: user.id.toString() });
      console.log(`Connected to room ${user.id}`);
    });

    socket.current.on("receive_message", (data) => {
      const { sender_id, message, timestamp = new Date().toISOString() } = data;

      if (sender_id === user.id) return;

      setMessages((prev) => ({
        ...prev,
        [sender_id]: [
          ...(prev[sender_id] || []),
          { text: message, sender: "them", timestamp },
        ],
      }));

      if (!selectedUser || selectedUser.id !== sender_id) {
        setUnread((prev) => ({
          ...prev,
          [sender_id]: (prev[sender_id] || 0) + 1,
        }));

        if (notificationSoundRef.current) {
          notificationSoundRef.current
            .play()
            .catch((err) => console.log("Audio play failed:", err));
        }
      }
    });

    socket.current.on("user_list", (users) => {
      const filtered = users.filter((u) => parseInt(u.id) !== user.id);
      const formattedOnline = filtered.map((u) => ({
        id: parseInt(u.id),
        name: u.name,
        username: u.username,
        avatar: u.avatar
          ? `https://s3.eu-central-1.amazonaws.com/strategytracker.net/AVATARS/${u.avatar}`
          : "https://s3.eu-central-1.amazonaws.com/strategytracker.net/assets-mh/user.png",
        online: true,
      }));

      setOnlineUsers(formattedOnline);
    });

    socket.current.on("typing", ({ sender_id }) => {
      setTypingUsers((prev) => ({ ...prev, [sender_id]: true }));
    });

    socket.current.on("stop_typing", ({ sender_id }) => {
      setTypingUsers((prev) => {
        const updated = { ...prev };
        delete updated[sender_id];
        return updated;
      });
    });

    return () => {
      socket.current.disconnect();
    };
  }, [user]);

  useEffect(() => {
    if (!user) return;

    const fetchRecentChats = async () => {
      try {
        const res = await fetch(
          `${SERVER_URL}/recent_chats?user_id=${user.id}`
        );
        const data = await res.json();

        const formattedChats = data.map((u) => ({
          id: u.id,
          name: u.name,
          username: u.username,
          avatar: u.avatar
            ? `https://s3.eu-central-1.amazonaws.com/strategytracker.net/AVATARS/${u.avatar}`
            : "https://s3.eu-central-1.amazonaws.com/strategytracker.net/assets-mh/user.png",
          online: false,
        }));

        const combinedUsers = [...formattedChats, ...onlineUsers].reduce(
          (acc, current) => {
            const existing = acc.find((item) => item.id === current.id);
            if (!existing) {
              acc.push(current);
            } else if (current.online) {
              existing.online = true;
            }
            return acc;
          },
          []
        );

        setAllUsers(combinedUsers);
      } catch (error) {
        console.error("Error fetching recent chats", error);
      }
    };

    fetchRecentChats();
  }, [user, onlineUsers]);

  const handleSelectUser = (u) => {
    setSelectedUser(u);

    setUnread((prev) => ({
      ...prev,
      [u.id]: 0,
    }));

    if (socket.current) {
      socket.current.off("chat_history");

      socket.current.on("chat_history", (history) => {
        setMessages((prev) => ({
          ...prev,
          [u.id]: history.map((msg) => ({
            text: msg.message,
            sender: msg.sender_id === user.id ? "me" : "them",
            timestamp: msg.timestamp,
          })),
        }));
      });

      socket.current.emit("get_chat_history", {
        user_id: user.id,
        other_user_id: u.id,
      });
    }
  };

  const handleSendMessage = () => {
    if (!input.trim() || !selectedUser) return;

    const data = {
      sender_id: user.id,
      recipient_id: selectedUser.id,
      message: input,
      timestamp: new Date().toISOString(),
    };

    socket.current.emit("send_message", data);

    setMessages((prev) => ({
      ...prev,
      [selectedUser.id]: [
        ...(prev[selectedUser.id] || []),
        { text: input, sender: "me", timestamp: data.timestamp },
      ],
    }));

    socket.current.emit("stop_typing", {
      sender_id: user.id,
      recipient_id: selectedUser.id,
    });

    setInput("");
  };

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInput(newValue);

    if (!selectedUser) return;

    socket.current.emit("typing", {
      sender_id: user.id,
      recipient_id: selectedUser.id,
    });

    if (typingTimeout.current) clearTimeout(typingTimeout.current);

    typingTimeout.current = setTimeout(() => {
      socket.current.emit("stop_typing", {
        sender_id: user.id,
        recipient_id: selectedUser.id,
      });
    }, 2000);
  };

  const handleClearConversation = async () => {
    if (!selectedUser) return;

    const confirmClear = window.confirm(
      `Clear conversation with ${selectedUser.name}?`
    );
    if (!confirmClear) return;

    // Clear local messages
    setMessages((prev) => ({
      ...prev,
      [selectedUser.id]: [],
    }));

    setUnread((prev) => ({
      ...prev,
      [selectedUser.id]: 0,
    }));

    // Optional: Clear from backend if supported
    try {
      await fetch(`${SERVER_URL}/clear_chat`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          user_id: user.id,
          other_user_id: selectedUser.id,
        }),
      });

      console.log("Conversation cleared on server.");
    } catch (error) {
      console.error("Failed to clear conversation on server:", error);
    }
  };

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages, selectedUser]);

  useEffect(() => {
    return () => {
      if (selectedUser) {
        setTypingUsers((prev) => {
          const updated = { ...prev };
          delete updated[selectedUser.id];
          return updated;
        });
      }
    };
  }, [selectedUser]);

  return (
      <div className="flex  overflow-y-auto max-h-[85vh] scrollbar-white bg-gray-100">
        {/* User List */}
        <div className="w-1/4 bg-white shadow-lg h-[85vh] flex flex-col">
          <h2 className="text-xl font-semibold p-4 border-b">Chats</h2>
          <div className="flex-1 overflow-y-auto scrollbar-white">
            <ul>
              {allUsers.map((u) => (
                  <li
                      key={u.id}
                      onClick={() => handleSelectUser(u)}
                      className={`p-4 cursor-pointer flex items-center hover:bg-gray-100 relative ${
                          selectedUser?.id === u.id ? "bg-gray-200" : ""
                      }`}
                  >
                    <img
                        src={u.avatar}
                        alt={u.name}
                        className="h-10 w-10 rounded-full mr-3 object-cover"
                    />
                    <div className="flex-1">
                      <div className="font-medium">{u.name}</div>
                      <div className="text-sm text-gray-400">@{u.username}</div>
                    </div>
                    {u.online && (
                        <div className="h-3 w-3 rounded-full bg-green-500"/>
                    )}
                    {unread[u.id] > 0 && (
                        <span
                            className="absolute top-2 right-4 bg-red-500 text-white text-xs rounded-full h-5 w-5 flex items-center justify-center animate-pulse">
                    {unread[u.id]}
                  </span>
                    )}
                  </li>
              ))}
            </ul>
          </div>
        </div>
        {/* Chat Window */}
        <div className="flex-1 flex flex-col">
          {selectedUser ? (
              <>
                <div className="p-4 bg-white shadow-md flex items-center justify-between">
                  <div className="flex items-center">
                    <img
                        src={selectedUser.avatar}
                        alt={selectedUser.name}
                        className="h-10 w-10 rounded-full mr-3 object-cover"
                    />
                    <h3 className="text-lg font-semibold">{selectedUser.name}</h3>
                  </div>
                  {/*<button*/}
                  {/*  onClick={handleClearConversation}*/}
                  {/*  className="text-red-500 hover:text-red-600 flex items-center gap-1 text-sm"*/}
                  {/*>*/}
                  {/*  <FiTrash2 className="h-4 w-4" />*/}
                  {/*  Clear Chat*/}
                  {/*</button>*/}
                </div>
                <div className="flex-1 p-8 overflow-y-auto space-y-4 bg-gray-50">
                  {(messages[selectedUser.id] || []).map((msg, index) => (
                      <div
                          key={index}
                          className={`flex ${
                              msg.sender === "me" ? "justify-end" : "justify-start"
                          }`}
                      >
                        {msg.sender === "them" && (
                            <img
                                src={selectedUser.avatar}
                                alt="avatar"
                                className="h-8 w-8 rounded-full mr-2 object-cover self-end"
                            />
                        )}
                        <div className="relative group">
                          <div
                              className={`max-w-xs p-3 rounded-lg text-sm ${
                                  msg.sender === "me"
                                      ? "bg-blue-500 text-white rounded-br-none"
                                      : "bg-white text-gray-800 border rounded-bl-none"
                              }`}
                          >
                            {msg.text}
                            <div className="text-[10px] text-right mt-1 opacity-60">
                              {new Date(msg.timestamp).toLocaleTimeString([], {
                                hour: "2-digit",
                                minute: "2-digit",
                              })}
                            </div>
                          </div>
                          {/* Tooltip */}
                          <div
                              className="absolute bottom-full mb-1 left-1/2 -translate-x-1/2 hidden group-hover:block bg-gray-800 text-white text-xs p-1 rounded-md whitespace-nowrap">
                            {new Date(msg.timestamp).toLocaleString()}
                          </div>
                        </div>
                        {msg.sender === "me" && (
                            <img
                                src={
                                  user.avatar
                                      ? `https://s3.eu-central-1.amazonaws.com/strategytracker.net/AVATARS/${user.avatar}`
                                      : "https://s3.eu-central-1.amazonaws.com/strategytracker.net/assets-mh/user.png"
                                }
                                alt="my avatar"
                                className="h-8 w-8 rounded-full ml-2 object-cover self-end"
                            />
                        )}
                      </div>
                  ))} {typingUsers[selectedUser?.id] && (
                    <div className="flex items-center space-x-4 text-sm text-gray-500 ">
                      <div className="h-2 w-2 rounded-full bg-green-500 animate-ping"/>
                      <span>{selectedUser?.name} is typing...</span>
                    </div>
                )}
                  <div ref={messagesEndRef}/>
                </div>
                <div className="p-4 bg-white border-t">
                  <div
                      className="flex items-center rounded-full border px-4 py-2 bg-gray-50 focus-within:ring-2 ring-blue-300 transition">
                    <input
                        type="text"
                        className="flex-1 bg-gray-50 outline-none text-sm placeholder-gray-400 border-0 focus:ring-0"
                        placeholder="Type your message..."
                        value={input}
                        onChange={handleInputChange}
                        onKeyDown={(e) => e.key === "Enter" && handleSendMessage()}
                    />
                    <button
                        onClick={handleSendMessage}
                        className="ml-2 text-blue-500 hover:text-blue-600 transition flex items-center"
                    >
                      <FiSend className="h-5 w-5"/>
                    </button>
                  </div>
                </div>
              </>
          ) : (
              <div className="flex items-center justify-center h-[85vh] text-gray-500">
                Select a user to start chatting
              </div>
          )}
        </div>
      </div>
  );
}
