import React from "react";
import RequireAuthGuard from "./components/auth/RouteGard";
import { createBrowserRouter } from "react-router-dom";
import ConferenceLayout from "./layout/conference-layout/conference-layout";
import ScopeOfPrograms from "./layout/qualitative-assessment-latest/program-scope";
import SystemChat from "./components/Chat/SystemChat";

/**
 * @module Routes
 * @description Defines and configures application routes using React Router DOM.
 *
 * This file serves as the central hub for routing in the application.
 * All application routes are defined here, ensuring a single, cohesive structure for navigation.
 *
 * Key Features:
 * 1. **Route Definition**:
 *    - All paths and their corresponding components are declared in this file.
 *    - Supports both public and private routes.
 *
 * 2. **Component Import**:
 *    - All routing components (pages, layouts, and error components) are imported here.
 *    - Lazy loading is utilized for optimized performance by loading components only when needed.
 *
 * 3. **Authentication Guards**:
 *    - Routes requiring authentication are protected by `RequireAuthGuard` to ensure only authorized users can access them.
 *
 * 4. **Error Handling**:
 *    - Each route can define a specific `errorElement` to display custom error messages or fallback components for failed navigation.
 *
 * Example Overview:
 * - Route Path: `/dashboard`
 * - Component: `<Dashboard />`
 * - Protected: Yes, by `RequireAuthGuard`
 * - Error Handling: Displays `<ErrorComponent />` on navigation errors.
 *
 * Includes:
 * - Authentication guards to protect private routes.
 * - Lazy-loaded components for optimized performance.
 * - A clear routing structure for public and private areas of the application.
 *
 * Example:
 * ```javascript
 * const routes = createBrowserRouter([
 *   {
 *     path: "/",
 *     element: <AuthLayout />, // Public layout for unauthenticated users
 *     children: [
 *       { index: true, element: <Home /> }, // Default route
 *       { path: "login", element: <Login /> }, // Login page
 *     ],
 *   },
 *   {
 *     path: "/dashboard",
 *     element: <RequireAuthGuard><DashboardLayout /></RequireAuthGuard>, // Private route with auth guard
 *     children: [
 *       { path: "profile", element: <Profile /> }, // Profile page
 *       { path: "settings", element: <Settings /> }, // Settings page
 *     ],
 *   },
 * ]);
 * ```
 *
 */

/**
 * @description Lazy loads the component to optimize performance.
 *
 * Lazy loading ensures that the component is only loaded into the application
 * when it's required (e.g., when the user navigates to the corresponding route).
 * This reduces the initial bundle size and improves application load time.
 *
 * Example usage:
 * - Route Path: "/hipd-activities"
 * - Component: HIPDActivities
 * - Description: Displays a dashboard of HIPD activities.
 *
 * ```javascript
 * // Lazy load the HIPDActivities component
 * const HIPDActivities = React.lazy(() =>
 *   import("./layout/GlobalOperations/Activities/HIPD-activities/HIPDActivities")
 * );
 *
 * // Define the routes
 * export const router = createBrowserRouter([
 *   {
 *     path: "/",
 *     errorElement: <ErrorComponent />,
 *     element: (
 *       <RequireAuthGuard>
 *         <Dashboard />
 *       </RequireAuthGuard>
 *     ),
 *     children: [
 *       {
 *         path: "HIPD-activities",
 *         element: <HIPDActivities />,
 *       },
 *     ],
 *   },
 * ]);
 * ```

 */

// Lazy load the components
const HIPDActivities = React.lazy(() =>
  import("./layout/GlobalOperations/Activities/HIPD-activities/HIPDActivities")
);
const GDActivities = React.lazy(() =>
  import("./layout/GlobalOperations/Activities/GD-activities/GDActivities")
);
const ProgramActivities = React.lazy(() =>
  import(
    "./layout/GlobalOperations/Activities/ProgramActivities/ProgramActivities"
  )
);
const HIPD = React.lazy(() => import("./layout/hipdEntered/hipdEntered"));
const FetchFeedBack = React.lazy(() => import("./layout/feedback"));
const Home = React.lazy(() => import("./layout/home/home"));
const Dashboard = React.lazy(() => import("./layout/dashboard/dashboard"));
const OverseasActivities = React.lazy(() =>
  import("./layout/OverseasesEntry/OverseasActivities")
);
const Earth = React.lazy(() => import("./layout/overview/overview"));
const GlobalOperations = React.lazy(() =>
  import("./layout/GlobalOperations/ResultChain/GlobalOperationsActivities")
);
const ChatApp = React.lazy(() => import("./layout/MhgAI/MhgAI"));
const Complains = React.lazy(() => import("./layout/complains/complains"));
const MonitoringActionTracker = React.lazy(() =>
  import("./layout/MonitoringActionTracker/MonitoringActionTracker")
);
const Governance = React.lazy(() => import("./layout/Governance/Governance"));
const OverseasActivitiesReport = React.lazy(() =>
  import("./layout/OverseasActivitiesReport/overseasactivitiesreport")
);
// const HIPD = React.lazy(() => import("./layout/hipdEntered/hipdEntered"));
const MHEMS = React.lazy(() => import("./layout/Mhems/mhems"));
const Directory = React.lazy(() => import("./layout/Directory/directory"));
const Activation = React.lazy(() => import("./layout/Account/Activation"));
const ConferenceOverview = React.lazy(() =>
  import("./layout/ConferenceOverview/index.jsx")
);
const Recovery = React.lazy(() => import("./layout/Account/Recovery"));
const RecoveryConfirmation = React.lazy(() =>
  import("./layout/Account/RecoveryConfirmation")
);
const Profile = React.lazy(() => import("./layout/Account/Profile"));
const ProjectLog = React.lazy(() => import("./layout/ProjectLog/ProjectLog"));
const RiskRegister = React.lazy(() =>
  import("./layout/RiskRegisterReport/RiskRegisterReport")
);
const MealPlanning = React.lazy(() =>
  import("./layout/meal-planning/index.jsx")
);
const FinancialFeedback = React.lazy(() =>
  import("./layout/FinancialFeedback/index.jsx")
);
const OSDashboard = React.lazy(() => import("./layout/OSDashboard/index.jsx"));
const CreateBeneficiary = React.lazy(() =>
  import("./layout/OSDashboard/pages/StepperForm/index.jsx")
);
const CreateMealPlan = React.lazy(() =>
  import("./layout/meal-planning/CreateMealPlan.jsx")
);
const UpdateMealPlan = React.lazy(() =>
  import("./layout/meal-planning/UpdateMealPlan.jsx")
);
const QualitativeAssessments = React.lazy(() =>
  import("./layout/QualitativeAssessments/QualitativeAssessment")
);
const QualitativeAssessmentLatest = React.lazy(() =>
  import("./layout/qualitative-assessment-latest")
);

const ManageQAssessments = React.lazy(() =>
  import("./layout/QualitativeAssessments/listQA")
);
const SMTDashboard = React.lazy(() =>
  import("./layout/SMTDashboard/SMTDashboard")
);
const GlobalResultsFramework = React.lazy(() =>
  import("./layout/GlobalResultsFramework/GlobalResultsFramework")
);
const DepartmentActivities = React.lazy(() =>
  import("./layout/GlobalOperations/Activities/DepartmentActivities")
);
const PMTDashboard = React.lazy(() => import("./layout/PMTDashboard"));
const CallForProposal = React.lazy(() =>
  import(
    "./layout/PMTDashboard/pages/general-pages/call-for-proposal/call-for-proposal"
  )
);
const CreateConceptNote = React.lazy(() =>
  import(
    "./layout/PMTDashboard/pages/general-pages/create-concept-note/create-concept-note"
  )
);
const AuthLayout = React.lazy(() => import("./layout/auth-layout/auth-layout"));
const ErrorComponent = React.lazy(() =>
  import("./components/PMTDashboard/common/error-component")
);
const ProjectDetails = React.lazy(() =>
  import("./layout/PMTDashboard/pages/general-pages/proposal-details")
);
const FranceResultsFramework = React.lazy(() =>
  import("./layout/FranceResultsFramework/FranceResultsFramework")
);

const CreateProposal = React.lazy(() =>
  import(
    "./layout/PMTDashboard/pages/general-pages/create-proposal/create-proposal"
  )
);
const FranceFrameWork = React.lazy(() => import("./layout/france-framework"));
const ScoringTemplate = React.lazy(() =>
  import("./layout/PMTDashboard/pages/tabs-pages/scoring-template")
);
const FundingAgreement = React.lazy(() =>
  import("./layout/PMTDashboard/pages/tabs-pages/funding-agreement")
);
const HumanResourceEdit = React.lazy(() =>
  import(
    "./layout/PMTDashboard/pages/general-pages/proposal-details/human-resource-edit"
  )
);
const ProcurementPlan = React.lazy(() =>
  import(
    "./layout/PMTDashboard/pages/general-pages/proposal-details/procurement-plan"
  )
);
const AssetRegister = React.lazy(() =>
  import(
    "./layout/PMTDashboard/pages/general-pages/proposal-details/asset-register"
  )
);

const RiskRegisterDetails = React.lazy(() =>
  import(
    "./layout/PMTDashboard/pages/general-pages/proposal-details/risk-register"
  )
);

const MealReporting = React.lazy(() =>
  import(
    "./layout/PMTDashboard/pages/general-pages/proposal-details/meal-reporting"
  )
);

const ChangeRequestForm = React.lazy(() =>
  import(
    "./layout/PMTDashboard/pages/general-pages/proposal-details/change-request-form"
  )
);

const ReportMisConduct = React.lazy(() => import("./layout/report-misconduct"));

export const router = createBrowserRouter([
  {
    /**
     * Public routes accessible without authentication.
     *
     * These routes are for general users like login, password recovery, etc.
     */
    path: "/",
    element: <AuthLayout />,
    errorElement: <ErrorComponent />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "forget-password",
        element: <Recovery />,
      },
      {
        path: "recovery-confirmation",
        element: <RecoveryConfirmation />,
      },
      {
        path: "confirmation",
        element: <Activation />,
      },
    ],
  },
  {
    /**
     * Routes for authenticated users.
     *
     * Uses an authentication guard and a specific layout for logged-in users.
     */
    path: "/",
    element: (
      <RequireAuthGuard>
        <ConferenceLayout />
      </RequireAuthGuard>
    ),
    errorElement: <ErrorComponent />,
    children: [
      {
        path: "conference-overview",
        element: <ConferenceOverview />,
      },
    ],
  },
  {
    /**
     * Authenticated routes using a dashboard layout.
     *
     * Includes various modules like PMT dashboard, meal planning, complaints, etc.
     */
    path: "/",
    errorElement: <ErrorComponent />,
    element: (
      <RequireAuthGuard>
        <Dashboard />
      </RequireAuthGuard>
    ),
    children: [
      {
        /**
         * @description Nested routes
         * Example of a nested route structure:
         * - Path: "/pmt-dashboard/proposal-discussion"
         * - Component: ProjectDetails
         * - Guarded by: RequireAuthGuard
         * - Error handler: ErrorComponent
         *
         * Configuration:
         * ```javascript
         * {
         *   path: "/pmt-dashboard",
         *   element: <RequireAuthGuard><Dashboard /></RequireAuthGuard>,
         *   errorElement: <ErrorComponent />,
         *   children: [
         *     {
         *       path: "proposal-discussion",
         *       element: <ProjectDetails />,
         *     },
         *   ],
         * }
         * ```
         */
        path: "pmt-dashboard",
        element: <PMTDashboard />,
        errorElement: <ErrorComponent />,
        children: [
          {
            path: "proposal-discussion",
            element: <ProjectDetails />,
          },
          {
            path: "call-for-proposal",
            element: <CallForProposal />,
          },
          {
            path: "create-concept-note",
            element: <CreateConceptNote />,
          },
          {
            path: "create-proposal",
            element: <CreateProposal />,
          },
          {
            path: "scoring-template",
            element: <ScoringTemplate />,
          },
          {
            path: "funding-agreement",
            element: <FundingAgreement />,
          },
          {
            path: "asset-register",
            element: <AssetRegister />,
          },
          {
            path: "risk-register",
            element: <RiskRegisterDetails />,
          },
          {
            path: "meal-reporting",
            element: <MealReporting />,
          },
          {
            path: "human-resource",
            element: <HumanResourceEdit />,
          },
          {
            path: "procurement-plan",
            element: <ProcurementPlan />,
          },
          {
            path: "change-request-form",
            element: <ChangeRequestForm />,
          },
        ],
      },
      {
        path: "overview",
        element: <Earth />,
      },

      {
        path: "overseas-activities",
        element: <OverseasActivities />,
      },
      {
        path: "report-complaint",
        element: <ReportMisConduct />,
      },
      {
        path: "os-report",
        element: <OverseasActivitiesReport />,
      },
      {
        path: "generative-ai",
        element: <ChatApp />,
      },
      {
        path: "complains",
        element: <Complains />,
      },
      {
        path: "pmf-tracker",
        element: <MonitoringActionTracker />,
      },
      {
        path: "governance",
        element: <Governance />,
      },
      {
        path: "hipd",
        element: <HIPD />,
      },
      {
        path: "meetings",
        element: <MHEMS />,
      },
      {
        path: "directory",
        element: <Directory />,
      },
      {
        path: "project-logs",
        element: <ProjectLog />,
      },
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "risk-register",
        element: <RiskRegister />,
      },
      {
        path: "conversation",
        element: <SystemChat />,
      },
      {
        path: "meal-planning",
        element: <MealPlanning />,
        children: [
          {
            path: "create-meal-plan",
            element: <CreateMealPlan />,
          },
          {
            path: "update-meal-plan/:id",
            element: <UpdateMealPlan />,
          },
        ],
      },
      {
        path: "financial-feedback",
        element: <FinancialFeedback />,
      },
      {
        path: "os-dashboard",
        element: <OSDashboard />,
        children: [
          {
            path: "create-new",
            element: <CreateBeneficiary />,
          },
        ],
      },
      {
        path: "qualitative-assessments",
        element: <QualitativeAssessments />,
      },
      {
        path: "qualitative-assessments-latest",
        element: <QualitativeAssessmentLatest />,
        children: [
          {
            path: "scope-of-all-programs",
            element: <ScopeOfPrograms />,
          },
        ],
      },
      {
        path: "manage-assessments",
        element: <ManageQAssessments />,
      },
      {
        path: "trustees-dashboard",
        element: <SMTDashboard />,
      },
      {
        path: "global-framework",
        element: <GlobalResultsFramework />,
      },
      {
        path: "france-framework",
        element: <FranceFrameWork />,
      },
      {
        path: "department-activities",
        element: <DepartmentActivities />,
      },
      {
        path: "HIPD-activities",
        element: <HIPDActivities />,
      },
      {
        path: "GD-activities",
        element: <GDActivities />,
      },
      {
        path: "uk-programs",
        element: <ProgramActivities />,
      },
      {
        path: "global-operations",
        element: <GlobalOperations />,
      },
      {
        path: "france-results-framework",
        element: <FranceResultsFramework />,
      },
      {
        path: "feedback",
        element: <FetchFeedBack />,
      },
    ],
  },
]);
