// filtersData.js
export const getFiltersData = (formattedCountries) => [
  {
    type: "select",
    FieldProps: {
      name: "year",
      label: "Year",
      placeholder: "Select Year",
    },
    options: [
      { label: "2020", value: "2020" },
      { label: "2021", value: "2021" },
      { label: "2022", value: "2022" },
      { label: "2023", value: "2023" },
      { label: "2024", value: "2024" },
      { label: "2025", value: "2025" },
    ],
  },
  {
    type: "select",
    FieldProps: {
      isMulti: true,
      name: "countries",
      label: "Countries",
      placeholder: "Select Country",
    },
    options: formattedCountries || [],
  },
];

export const data = [
  {
    country: "USA",
    orphans: 150,
    teachers: 20,
    principals: 5,
    parents: 300,
    washCommunity: 100,
    washCommittee: 50,
    washHealthOfficials: 10,
    adolescentGirls: 200,
    healthCommunity: 120,
    healthCommunityWomen: 80,
    cataractSurgery: 15,
    healthOfficials: 25,
    healthMidwives: 12,
    foodCommunity: 250,
    foodSecurityStudents: 180,
    foodSecurityFarmers: 75,
    livelyhoodTrainees: 90,
    livelyhoodFarmers: 60,
    emergenciesCommunity: 50,
    emergenciesEWS: 20,
  },
  {
    country: "Canada",
    orphans: 120,
    teachers: 18,
    principals: 6,
    parents: 250,
    washCommunity: 90,
    washCommittee: 40,
    washHealthOfficials: 8,
    adolescentGirls: 180,
    healthCommunity: 110,
    healthCommunityWomen: 70,
    cataractSurgery: 10,
    healthOfficials: 20,
    healthMidwives: 10,
    foodCommunity: 220,
    foodSecurityStudents: 150,
    foodSecurityFarmers: 65,
    livelyhoodTrainees: 80,
    livelyhoodFarmers: 50,
    emergenciesCommunity: 45,
    emergenciesEWS: 15,
  },
  {
    country: "India",
    orphans: 500,
    teachers: 50,
    principals: 15,
    parents: 1000,
    washCommunity: 300,
    washCommittee: 200,
    washHealthOfficials: 30,
    adolescentGirls: 400,
    healthCommunity: 350,
    healthCommunityWomen: 200,
    cataractSurgery: 50,
    healthOfficials: 80,
    healthMidwives: 40,
    foodCommunity: 700,
    foodSecurityStudents: 500,
    foodSecurityFarmers: 300,
    livelyhoodTrainees: 200,
    livelyhoodFarmers: 150,
    emergenciesCommunity: 100,
    emergenciesEWS: 60,
  },
  {
    country: "Total",
    orphans: 500,
    teachers: 50,
    principals: 15,
    parents: 1000,
    washCommunity: 300,
    washCommittee: 200,
    washHealthOfficials: 30,
    adolescentGirls: 400,
    healthCommunity: 350,
    healthCommunityWomen: 200,
    cataractSurgery: 50,
    healthOfficials: 80,
    healthMidwives: 40,
    foodCommunity: 700,
    foodSecurityStudents: 500,
    foodSecurityFarmers: 300,
    livelyhoodTrainees: 200,
    livelyhoodFarmers: 150,
    emergenciesCommunity: 100,
    emergenciesEWS: 60,
  },
];

export const achievements = [
  {
    program: { name: "Education", target: 15, achievement: 15 },
  },
  {
    program: { name: "WASH", target: 15, achievement: 15 },
  },
  {
    program: { name: "Emergencies", target: 15, achievement: 15 },
  },
];

export const tools = [
  {
    name: "Orphans",
    target: 10,
    achievement: 10,
  },
  {
    name: "Teachers",
    target: 2,
    achievement: 2,
  },
  {
    name: "Principals",
    target: 1,
    achievement: 1,
  },
  {
    name: "Parents",
    target: 2,
    achievement: 2,
  },
  {
    name: "Wash Community",
    target: 2,
    achievement: 2,
  },
  {
    name: "Wash Committe",
    target: 2,
    achievement: 2,
  },
  {
    name: "Wash Health Officials",
    target: 2,
    achievement: 2,
  },
];
