import { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { getPartnerCountries } from "src/services/pmt-dashboard/countries-api";
import { dummyData } from "./data";

export const useFAQsMonitoring = () => {
  const [search, setSearch] = useState({});
  const [scheduleType, setScheduleType] = useState({});
  const [filteredData, setFilteredData] = useState([]);

  // Fetch partner countries using React Query
  const { data: partnerCountries } = useQuery({
    queryKey: ["PartnerCountries"],
    queryFn: getPartnerCountries,
    staleTime: 10000,
  });

  // Format countries for filter options
  const formattedCountries =
    partnerCountries?.data?.map((country) => ({
      label: country.name,
      value: country.id,
    })) || [];

  const calculateColumnTotal = (key) => {
    return filteredData.reduce((sum, item) => sum + item[key], 0);
  };

  const applyFilters = (data, filters) => {
    const { year, countries, area, tool } = filters;

    // Step 1: Filter by Year and Country
    const countryData = data.filter(
      (item) => item.year === year && item.country.id === countries
    );

    // If no country is found in the given year, return empty data
    if (countryData.length === 0) return [];

    // Select the country and total assessments
    const countryInfo = countryData[0];

    // Step 2: Filter by Program (Area) if specified
    if (area) {
      const selectedProgram = countryInfo.programs.find(
        (program) => program.program.id === area
      );

      // If program found, display program data
      if (selectedProgram) {
        if (tool) {
          // Filter by tool if specified
          const selectedTool = selectedProgram.tools.find(
            (t) => t.tool.id === tool
          );
          if (selectedTool) {
            return [
              {
                country: countryInfo.country.country,
                totalAssessments: selectedTool.totalAssessments,
                assessmentsCurrentMonth: selectedTool.assessmentsCurrentMonth,
                assessmentsCurrentWeek: selectedTool.assessmentsCurrentWeek,
                assessmentsCurrentDay: selectedTool.assessmentsCurrentDay,
              },
            ];
          }
        } else {
          // If no tool selected, return the program's data
          return [
            {
              country: countryInfo.country.country,
              program: selectedProgram.program.program,
              totalAssessments: selectedProgram.totalAssessments,
              assessmentsCurrentMonth: selectedProgram.assessmentsCurrentMonth,
              assessmentsCurrentWeek: selectedProgram.assessmentsCurrentWeek,
              assessmentsCurrentDay: selectedProgram.assessmentsCurrentDay,
            },
          ];
        }
      }
    } else {
      // If no program (area) is selected, return country level data
      return [
        {
          country: countryInfo.country.country,
          totalAssessments: countryInfo.totalAssessments,
          assessmentsCurrentMonth: countryInfo.assessmentsCurrentMonth,
          assessmentsCurrentWeek: countryInfo.assessmentsCurrentWeek,
          assessmentsCurrentDay: countryInfo.assessmentsCurrentDay,
        },
      ];
    }

    // Return an empty array if no valid data found
    return [];
  };

  useEffect(() => {
    setFilteredData(applyFilters(dummyData, search));
  }, [search]);

  return {
    scheduleType,
    setSearch,
    setScheduleType,
    formattedCountries,
    filteredData,
    calculateColumnTotal,
  };
};
