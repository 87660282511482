import React, { useMemo } from "react";
import { CustomFilters } from "src/components/PMTDashboard/filters";
import { useReport } from "./use-report";
import { achievements, data, getFiltersData, tools } from "./data";
import { CustomTable } from "src/components/PMTDashboard/common/custom-table";
import Card from "src/components/PMTDashboard/common/custom-card";

const Report = () => {
  const { setSearch, formattedCountries, params, setParams } = useReport();
  const columns = [
    {
      accessorFn: (row) => row?.country ?? "-",
      id: "country",
      cell: (info) => info.getValue(),
      header: () => <span>Country</span>,
      isSortable: false,
    },
    {
      accessorFn: (row) => row?.orphans ?? "-",
      id: "orphans",
      cell: (info) => info.getValue(),
      header: () => <span>Orphans</span>,
      isSortable: false,
    },
    {
      accessorFn: (row) => row?.teachers ?? "-",
      id: "teachers",
      cell: (info) => info.getValue(),
      header: () => <span>Teachers</span>,
      isSortable: false,
    },
    {
      accessorFn: (row) => row?.principals ?? "-",
      id: "principals",
      cell: (info) => info.getValue(),
      header: () => <span>Principals</span>,
      isSortable: false,
    },
    {
      accessorFn: (row) => row?.parents ?? "-",
      id: "parents",
      cell: (info) => info.getValue(),
      header: () => <span>Parents</span>,
      isSortable: false,
    },
    {
      accessorFn: (row) => row?.washCommunity ?? "-",
      id: "washCommunity",
      cell: (info) => info.getValue(),
      header: () => <span>Wash Community</span>,
      isSortable: false,
    },
    {
      accessorFn: (row) => row?.washCommittee ?? "-",
      id: "washCommittee",
      cell: (info) => info.getValue(),
      header: () => <span>Wash Committee</span>,
      isSortable: false,
    },
    {
      accessorFn: (row) => row?.washHealthOfficials ?? "-",
      id: "washHealthOfficials",
      cell: (info) => info.getValue(),
      header: () => <span>Wash Health Officials</span>,
      isSortable: false,
    },
    {
      accessorFn: (row) => row?.adolescentGirls ?? "-",
      id: "adolescentGirls",
      cell: (info) => info.getValue(),
      header: () => <span>Adolescent Girls</span>,
      isSortable: false,
    },
    {
      accessorFn: (row) => row?.healthCommunity ?? "-",
      id: "healthCommunity",
      cell: (info) => info.getValue(),
      header: () => <span>Health Community</span>,
      isSortable: false,
    },
    {
      accessorFn: (row) => row?.healthCommunityWomen ?? "-",
      id: "healthCommunityWomen",
      cell: (info) => info.getValue(),
      header: () => <span>Health Community Women</span>,
      isSortable: false,
    },
    {
      accessorFn: (row) => row?.cataractSurgery ?? "-",
      id: "cataractSurgery",
      cell: (info) => info.getValue(),
      header: () => <span>Cataract Surgery</span>,
      isSortable: false,
    },
    {
      accessorFn: (row) => row?.healthOfficials ?? "-",
      id: "healthOfficials",
      cell: (info) => info.getValue(),
      header: () => <span>Health Officials</span>,
      isSortable: false,
    },
    {
      accessorFn: (row) => row?.healthMidwives ?? "-",
      id: "healthMidwives",
      cell: (info) => info.getValue(),
      header: () => <span>Health Midwives</span>,
      isSortable: false,
    },
    {
      accessorFn: (row) => row?.foodCommunity ?? "-",
      id: "foodCommunity",
      cell: (info) => info.getValue(),
      header: () => <span>Food Community</span>,
      isSortable: false,
    },
    {
      accessorFn: (row) => row?.foodSecurityStudents ?? "-",
      id: "foodSecurityStudents",
      cell: (info) => info.getValue(),
      header: () => <span>Food Security Students</span>,
      isSortable: false,
    },
    {
      accessorFn: (row) => row?.foodSecurityFarmers ?? "-",
      id: "foodSecurityFarmers",
      cell: (info) => info.getValue(),
      header: () => <span>Food Security Farmers</span>,
      isSortable: false,
    },
    {
      accessorFn: (row) => row?.livelyhoodTrainees ?? "-",
      id: "livelyhoodTrainees",
      cell: (info) => info.getValue(),
      header: () => <span>Livelyhood Trainees</span>,
      isSortable: false,
    },
    {
      accessorFn: (row) => row?.livelyhoodFarmers ?? "-",
      id: "livelyhoodFarmers",
      cell: (info) => info.getValue(),
      header: () => <span>Livelyhood Farmers</span>,
      isSortable: false,
    },
    {
      accessorFn: (row) => row?.emergenciesCommunity ?? "-",
      id: "emergenciesCommunity",
      cell: (info) => info.getValue(),
      header: () => <span>Emergencies Community</span>,
      isSortable: false,
    },
    {
      accessorFn: (row) => row?.emergenciesEWS ?? "-",
      id: "emergenciesEWS",
      cell: (info) => info.getValue(),
      header: () => <span>Emergencies EWS</span>,
      isSortable: false,
    },
  ];

  const programColumns = [
    {
      accessorFn: (row) => row?.program?.name ?? "-",
      id: "name",
      cell: (info) => info.getValue(),
      header: () => <span>Program</span>,
      isSortable: false,
    },
    {
      accessorFn: (row) => row?.program?.target ?? "-",
      id: "target",
      cell: (info) => info.getValue(),
      header: () => <span>Target</span>,
      isSortable: false,
    },
    {
      accessorFn: (row) => row?.program?.achievement ?? "-",
      id: "achievement",
      cell: (info) => info.getValue(),
      header: () => <span>Achievement</span>,
      isSortable: false,
    },
  ];

  const toolColumns = [
    {
      accessorFn: (row) => row?.name ?? "-",
      id: "name",
      cell: (info) => info.getValue(),
      header: () => <span>Tool</span>,
      isSortable: false,
    },
    {
      accessorFn: (row) => row?.target ?? "-",
      id: "target",
      cell: (info) => info.getValue(),
      header: () => <span>Target</span>,
      isSortable: false,
    },
    {
      accessorFn: (row) => row?.achievement ?? "-",
      id: "achievement",
      cell: (info) => info.getValue(),
      header: () => <span>Achievement</span>,
      isSortable: false,
    }
  ];

  return (
    <div>
      <div
        className="flex items-center p-4 my-4 text-[15px] text-blue-600 border border-blue-300 rounded-lg bg-blue-100 "
        role="alert"
      >
        <svg
          className="flex-shrink-0 inline w-4 h-4 me-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
        </svg>
        <span className="sr-only">Info</span>
        <div className="font-medium">
          <span>Info!</span> Please select year and country for the further
          information
        </div>
      </div>
      <div className="my-5 border border-gray-200 p-3 rounded-md flex items-center">
        <div>
          <p className="font-medium mb-1">Filters</p>
          {/* Global Custom Filters */}
          <CustomFilters
            onChanged={(e) => {
              setSearch(e);
            }}
            tableHeaderData={getFiltersData(formattedCountries)}
          />
        </div>
      </div>
      <Card title="Year 2024 targets assigned">
        <CustomTable
          data={data}
          columns={columns}
          // isLoading={isLoading}
          // isFetching={isFetching}
          // isError={isError}
          isPagination
          isSuccess={true}
          showSerialNo
          // totalPages={items?.data?.total_pages ?? 0}
          // currentPage={items?.data?.page ?? 1}
          onPageChange={(onPageData) => {
            setParams({
              page: onPageData,
              perPage: params.perPage,
            });
          }}
        />
      </Card>
      <br />
      <Card title="Year 2024 Program wise Achievements">
        <CustomTable
          data={achievements}
          columns={programColumns}
          // isLoading={isLoading}
          // isFetching={isFetching}
          // isError={isError}
          isPagination
          isSuccess={true}
          showSerialNo
          // totalPages={items?.data?.total_pages ?? 0}
          // currentPage={items?.data?.page ?? 1}
          onPageChange={(onPageData) => {
            setParams({
              page: onPageData,
              perPage: params.perPage,
            });
          }}
        />
      </Card>
      <br />
      <Card title="Year 2024 Tool wise Achievements">
        <CustomTable
          data={tools}
          columns={toolColumns}
          // isLoading={isLoading}
          // isFetching={isFetching}
          // isError={isError}
          isPagination
          isSuccess={true}
          showSerialNo
          // totalPages={items?.data?.total_pages ?? 0}
          // currentPage={items?.data?.page ?? 1}
          onPageChange={(onPageData) => {
            setParams({
              page: onPageData,
              perPage: params.perPage,
            });
          }}
        />
      </Card>
    </div>
  );
};

export default Report;
