import { CustomFilters } from "src/components/PMTDashboard/filters";
import { getChartsFilterData, getFiltersData } from "./data";
import { useFAQsMonitoring } from "./use-faqs-monitoring";
import Card from "src/components/PMTDashboard/common/custom-card";

const FAQsMonitoring = () => {
  const {
    scheduleType,
    setSearch,
    setScheduleType,
    formattedCountries,
    filteredData,
    calculateColumnTotal,
  } = useFAQsMonitoring();

  return (
    <div>
      <div
        className="flex items-center p-4 my-4 text-[15px] text-blue-600 border border-blue-300 rounded-lg bg-blue-100"
        role="alert"
      >
        <svg
          className="flex-shrink-0 inline w-4 h-4 me-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
        </svg>
        <span className="sr-only">Info</span>
        <div className="font-medium">
          <span>Info!</span> Please select a year and country to view the data.
        </div>
      </div>
      <div className="my-5 border border-gray-200 p-3 rounded-md flex items-center">
        <div>
          <p className="font-medium mb-1">Filters</p>
          <CustomFilters
            onChanged={(e) => setSearch(e)}
            tableHeaderData={getFiltersData(formattedCountries)}
          />
        </div>
      </div>

      {filteredData?.length > 0 ? (
        <>
          <Card title="Assessment Summary Overview">
            <div className="mt-4">
              <table className="min-w-full border-collapse border border-gray-300">
                <thead>
                  <tr className="bg-blue-500">
                    <th className="border border-gray-300 px-4 py-2 text-center text-sm text-white">
                      Country
                    </th>
                    <th className="border border-gray-300 px-4 py-2 text-center text-sm text-white">
                      Total Assessments
                    </th>
                    <th className="border border-gray-300 px-4 py-2 text-center text-sm text-white">
                      This Month
                    </th>
                    <th className="border border-gray-300 px-4 py-2 text-center text-sm text-white">
                      This Week
                    </th>
                    <th className="border border-gray-300 px-4 py-2 text-center text-sm text-white">
                      This Day
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData?.map((row, index) => (
                    <tr key={index}>
                      <td className="border border-gray-300 px-4 py-2 text-center">
                        {row.country}
                      </td>
                      <td className="border border-gray-300 px-4 py-2 text-center">
                        {row.totalAssessments}
                      </td>
                      <td className="border border-gray-300 px-4 py-2 text-center">
                        {row.assessmentsCurrentMonth}
                      </td>
                      <td className="border border-gray-300 px-4 py-2 text-center">
                        {row.assessmentsCurrentWeek}
                      </td>
                      <td className="border border-gray-300 px-4 py-2 text-center">
                        {row.assessmentsCurrentDay}
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td className="border border-gray-300 px-4 py-2 text-center font-bold">
                      Total
                    </td>
                    <td className="border border-gray-300 px-4 py-2 text-center font-bold">
                      {calculateColumnTotal("totalAssessments")}
                    </td>
                    <td className="border border-gray-300 px-4 py-2 text-center font-bold">
                      {calculateColumnTotal("assessmentsCurrentMonth")}
                    </td>
                    <td className="border border-gray-300 px-4 py-2 text-center font-bold">
                      {calculateColumnTotal("assessmentsCurrentWeek")}
                    </td>
                    <td className="border border-gray-300 px-4 py-2 text-center font-bold">
                      {calculateColumnTotal("assessmentsCurrentDay")}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Card>
        </>
      ) : (
        <div className="text-center mt-8">
          <p className="text-gray-500">
            No data available. Please select a year to proceed.
          </p>
        </div>
      )}
      <Card title="Bar Chart">
        <div className="my-5 border border-gray-200 p-3 rounded-md flex items-center">
          <div>
            <p className="font-medium mb-1">Filters</p>
            <CustomFilters
              onChanged={(e) => setScheduleType(e)}
              tableHeaderData={getChartsFilterData()}
            />
          </div>
        </div>
      </Card>
    </div>
  );
};

export default FAQsMonitoring;
