import React from "react";
import { CustomFilters } from "src/components/PMTDashboard/filters";
import { getFiltersData } from "./data";
import { useProgress } from "./use-progress";
import HalfDonut from "./half-donut";
import NodeChart from "./node-chart";

const Progress = () => {
  const { setSearch, formattedCountries } = useProgress();

  return (
    <div>
      <div
        className="flex items-center p-4 my-4 text-[15px] text-blue-600 border border-blue-300 rounded-lg bg-blue-100 "
        role="alert"
      >
        <svg
          className="flex-shrink-0 inline w-4 h-4 me-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
        </svg>
        <span className="sr-only">Info</span>
        <div className="font-medium">
          <span>Info!</span> Please select year and country for the further
          information
        </div>
      </div>
      <div className="my-5 border border-gray-200 p-3 rounded-md flex items-center">
        <div>
          <p className="font-medium mb-1">Filters</p>
          {/* Global Custom Filters */}
          <CustomFilters
            onChanged={(e) => {
              setSearch(e);
            }}
            tableHeaderData={getFiltersData(formattedCountries)}
          />
        </div>
      </div>
      <div className="flex justify-center gap-12 my-16 mx-auto">
        <div className="w-[250px] border border-blue-600">
          <div className="h-[40px] border bg-blue-600 border-blue-600 flex items-center justify-center">
            <p className="text-white">Teachers</p>
          </div>
          <HalfDonut achieved={450} target={200} />
        </div>
      </div>
      <div className="flex flex-col gap-6">
        <div className="border border-gray-600">
          <div className="w-full h-[40px] bg-blue-600 flex items-center justify-center">
            <p className="text-white font-semibold">Orphans</p>
          </div>
          <div className="w-full h-[400px]">
            <NodeChart />
          </div>
        </div>
        <div className="border border-gray-600">
          <div className="w-full h-[40px] bg-blue-600 flex items-center justify-center">
            <p className="text-white font-semibold">Teachers</p>
          </div>
          <div className="w-full h-[400px]">
            <NodeChart />
          </div>
        </div>
        <div className="border border-gray-600">
          <div className="w-full h-[40px] bg-blue-600 flex items-center justify-center">
            <p className="text-white font-semibold">Principal</p>
          </div>
          <div className="w-full h-[400px]">
            <NodeChart />
          </div>
        </div>
        <div className="border border-gray-600">
          <div className="w-full h-[40px] bg-blue-600 flex items-center justify-center">
            <p className="text-white font-semibold">Parents/Gaurdians</p>
          </div>
          <div className="w-full h-[400px]">
            <NodeChart />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Progress;
