import { Handle, Position } from "@xyflow/react";
import React from "react";

const Year = ({ data }) => {
  // Ensure data.number is treated as a percentage
  const progress = `${data.number}%`;

  return (
    <div className="w-[200px] text-left border border-blue-600 p-2">
      {/* Outer bar */}
      <div className="w-full bg-gray-200 h-4 rounded">
        {/* Inner progress bar */}
        <div
          className={`${data.number >= 15 ? "bg-[#0A97B0]" : "bg-rose-500"} h-4 rounded`}
          style={{ width: progress }}
        ></div>
      </div>
      {/* Progress text */}
      <p className="mt-1 text-gray-700 font-semibold text-sm">
        {data.year}
      </p>
      <p className="text-gray-700 text-sm"><span className="font-semibold">Orphan Students:</span> {data.number}</p>
      <Handle type="source" position={Position.Right} />
      <Handle type="target" position={Position.Left} />
    </div>
  );
};

export default Year;
