import React from 'react';
import {
  Background,
  ReactFlow,
  Controls,
  useNodesState,
  useEdgesState,
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import { initialEdges, initialNodes } from './workflow.constants';
import ToolType from './ToolType';
import Year from './Year';
import Quarter from './Quarter';
import Country from './Country';

const nodeTypes = {
  'toolType': ToolType,
  'year': Year,
  'quarter': Quarter,
  'country': Country,
}

const NodeChart = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes)
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges)
 
  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      nodeTypes={nodeTypes}
      fitView
      >
        {/* <Background /> */}
        <Controls />
      </ReactFlow>  
  );
};
 
export default NodeChart;