import FAQsMonitoring from "./faqs-monitoring";
import Progress from "./progress";
import Report from "./report";
import Scope from "./scope";

// Tab items 
export const programScopeTabs = [
    {
      id: 1,
      title: "Scope",
      content: <Scope />,
      resource: "PMTProposal",
    },
    {
      id: 2,
      title: "Progress",
      content: <Progress />,
      resource: "PMTProposal",
    },
    {
      id: 3,
      title: "Quarter-wise Report",
      content: <Report />,
      resource: "PMTProposal",
    },
    {
      id: 4,
      title: "Monitoring of QAs",
      content: <FAQsMonitoring />,
      resource: "PMTProposal",
    },
  ];