import React from "react";
import ReactApexChart from "react-apexcharts";

const HalfDonut = ({ achieved, target }) => {
  const seriesValue = target > 0 ? (achieved / target) * 100 : 0; // Convert achieved into percentage for proper rendering

  const [state, setState] = React.useState({
    series: [seriesValue], // Use percentage for correct visualization
    options: {
      chart: {
        type: "radialBar",
        offsetY: -20,
        sparkline: { enabled: true },
      },
      fill: {
        colors: ["#1fa7c6"], 
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          hollow: { size: "60%" },
          track: {
            background: "#e7e7e7",
            strokeWidth: "100%",
            margin: 5,
          },
          dataLabels: {
            name: { show: false },
            value: {
              formatter: () => `${achieved} / ${target}`, // Show actual values
              fontSize: "24px", 
              fontWeight: "normal",
              show: true,
              offsetY: -10, 
            },
          },
        },
      },
      grid: { padding: { top: -10 } },
      labels: ["Progress"],
    },
  });

  return (
    <div>
      <ReactApexChart
        options={state.options}
        series={state.series}
        type="radialBar"
        height={300}
      />
    </div>
  );
};

export default HalfDonut;
