// filtersData.js
export const getFiltersData = (formattedCountries) => [
  {
    type: "select",
    FieldProps: {
      name: "year",
      label: "Year",
      placeholder: "Select Year",
    },
    options: [
      { label: "2020", value: "2020" },
      { label: "2021", value: "2021" },
      { label: "2022", value: "2022" },
      { label: "2023", value: "2023" },
      { label: "2024", value: "2024" },
      { label: "2025", value: "2025" },
    ],
  },
  {
    type: "select",
    FieldProps: {
      name: "countries",
      label: "Countries",
      placeholder: "Select Country",
    },
    options: [...formattedCountries, { value: 0, label: "All" }] || [],
  },
  {
    type: "select",
    FieldProps: {
      name: "area",
      label: "Thematic Area",
      placeholder: "Select Thematic Area",
    },
    options: [
      { value: 1, label: "Education" },
      { value: 2, label: "Emergencies" },
      { value: 3, label: "Food Security" },
      { value: 4, label: "Health" },
      { value: 5, label: "Livelihoods" },
      { value: 6, label: "WASH" },
    ],
  },
  {
    type: "select",
    FieldProps: {
      name: "tool",
      label: "Tool",
      placeholder: "Select Tool",
    },
    options: [
      { value: 1, label: "Orphan Tool" },
      { value: 2, label: "Parents Tool" },
      { value: 3, label: "Teachers Tool" },
      { value: 4, label: "Principal Tool" },
    ],
  },
];

export const getChartsFilterData = () => [
  {
    type: "select",
    FieldProps: {
      name: "schedule",
      label: "Schedule",
      placeholder: "Select Schedule",
    },
    options: [
      { value: 1, label: "Quarterly" },
      { value: 2, label: "Monthly" },
    ],
  },
];

export const dummyData = [
  {
    year: "2024",
    country: { id: 2, country: "Bangladesh" },
    totalAssessments: 1000,
    assessmentsCurrentMonth: 7,
    assessmentsCurrentWeek: 5,
    assessmentsCurrentDay: 1,
    programs: [
      {
        program: { id: 1, program: "Education" },
        totalAssessments: 600,
        assessmentsCurrentMonth: 7,
        assessmentsCurrentWeek: 5,
        assessmentsCurrentDay: 1,
        tools: [
          {
            tool: { id: 1, tool: "Orphan" },
            totalAssessments: 100,
            assessmentsCurrentMonth: 7,
            assessmentsCurrentWeek: 5,
            assessmentsCurrentDay: 1,
          },
          {
            tool: { id: 1, tool: "Teacher" },
            totalAssessments: 200,
            assessmentsCurrentMonth: 7,
            assessmentsCurrentWeek: 5,
            assessmentsCurrentDay: 1,
          },
        ],
      },
      {
        program: { id: 1, program: "WASH" },
        totalAssessments: 400,
        assessmentsCurrentMonth: 7,
        assessmentsCurrentWeek: 5,
        assessmentsCurrentDay: 1,
        tools: [
          {
            tool: { id: 1, tool: "WASH Committee" },
            totalAssessments: 250,
          },
        ],
      },
    ],
  },
  {
    year: "2024",
    country: { id: 3, country: "Gambia" },
    totalAssessments: 1000,
    assessmentsCurrentMonth: 7,
    assessmentsCurrentWeek: 5,
    assessmentsCurrentDay: 1,
    programs: [
      {
        program: { id: 1, program: "Education" },
        totalAssessments: 600,
        assessmentsCurrentMonth: 7,
        assessmentsCurrentWeek: 5,
        assessmentsCurrentDay: 1,
        tools: [
          {
            tool: { id: 1, tool: "Orphan" },
            totalAssessments: 100,
            assessmentsCurrentMonth: 7,
            assessmentsCurrentWeek: 5,
            assessmentsCurrentDay: 1,
          },
          {
            tool: { id: 1, tool: "Teacher" },
            totalAssessments: 200,
            assessmentsCurrentMonth: 7,
            assessmentsCurrentWeek: 5,
            assessmentsCurrentDay: 1,
          },
        ],
      },
      {
        program: { id: 1, program: "WASH" },
        totalAssessments: 400,
        assessmentsCurrentMonth: 7,
        assessmentsCurrentWeek: 5,
        assessmentsCurrentDay: 1,
        tools: [
          {
            tool: { id: 1, tool: "WASH Committee" },
            totalAssessments: 250,
          },
        ],
      },
    ],
  },
];
