import React, { useState } from "react";
import { CustomFilters } from "src/components/PMTDashboard/filters";
import { useScope } from "./use-scope";
import { getFiltersData } from "./data";
import Card from "src/components/PMTDashboard/common/custom-card";

const Scope = () => {
  const { setSearch, formattedCountries } = useScope();
  const [data, setData] = useState([
    {
      country: "Gambia",
      education: 0,
      WASH: 76,
      Health: 88,
      foodSecurity: 72,
      livelihoods: 69,
      emergencies: 0,
    },
    {
      country: "Malawi",
      education: 32,
      WASH: 22,
      Health: 10,
      foodSecurity: 13,
      livelihoods: 3,
      emergencies: 33,
    },
    {
      country: "Afghanistan",
      education: 12,
      WASH: 10,
      Health: 20,
      foodSecurity: 44,
      livelihoods: 3,
      emergencies: 8,
    },
  ]);

  const calculateColumnTotal = (column) => {
    return data.reduce((total, row) => total + row[column], 0);
  };

  const calculateGrandTotal = (row) => {
    return (
      row.education +
      row.WASH +
      row.Health +
      row.foodSecurity +
      row.livelihoods +
      row.emergencies
    );
  };

  return (
    <div>
      <div
        className="flex items-center p-4 my-4 text-[15px] text-blue-600 border border-blue-300 rounded-lg bg-blue-100 "
        role="alert"
      >
        <svg
          className="flex-shrink-0 inline w-4 h-4 me-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
        </svg>
        <span className="sr-only">Info</span>
        <div className="font-medium">
          <span>Info!</span> Please select year and country for the further
          information
        </div>
      </div>
      <div className="my-5 border border-gray-200 p-3 rounded-md flex items-center">
        <div>
          <p className="font-medium mb-1">Filters</p>
          {/* Global Custom Filters */}
          <CustomFilters
            onChanged={(e) => {
              setSearch(e);
            }}
            tableHeaderData={getFiltersData(formattedCountries)}
          />
        </div>
      </div>
      <Card title="Scope of All Programmes">
        <div className="my-6">
          <table className="min-w-full border-collapse border border-gray-300">
            <thead>
              <tr className="bg-blue-500">
                <th className="border border-gray-300 px-4 py-2 text-center text-sm text-white">
                  Country
                </th>
                <th className="border border-gray-300 px-4 py-2 text-center text-sm text-white">
                  Education
                </th>
                <th className="border border-gray-300 px-4 py-2 text-center text-sm text-white">
                  WASH
                </th>
                <th className="border border-gray-300 px-4 py-2 text-center text-sm text-white">
                  Health Total
                </th>
                <th className="border border-gray-300 px-4 py-2 text-center text-sm text-white">
                  Food Security
                </th>
                <th className="border border-gray-300 px-4 py-2 text-center text-sm text-white">
                  Livelihoods
                </th>
                <th className="border border-gray-300 px-4 py-2 text-center text-sm text-white">
                  Emergencies
                </th>
                <th className="border border-gray-300 px-4 py-2 text-center text-sm text-white">
                  Grand Total
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => (
                <tr key={index}>
                  <td className="border border-gray-300 px-4 py-2 text-center">
                    {row.country}
                  </td>
                  <td className="border border-gray-300 px-4 py-2 text-center">
                    {row.education}
                  </td>
                  <td className="border border-gray-300 px-4 py-2 text-center">
                    {row.WASH}
                  </td>
                  <td className="border border-gray-300 px-4 py-2 text-center">
                    {row.Health}
                  </td>
                  <td className="border border-gray-300 px-4 py-2 text-center">
                    {row.foodSecurity}
                  </td>
                  <td className="border border-gray-300 px-4 py-2 text-center">
                    {row.livelihoods}
                  </td>
                  <td className="border border-gray-300 px-4 py-2 text-center">
                    {row.emergencies}
                  </td>
                  <td className="border border-gray-300 px-4 py-2 text-center">
                    {calculateGrandTotal(row)}
                  </td>
                </tr>
              ))}
              <tr>
                <td className="border border-gray-300 px-4 py-2 text-center font-bold">
                  Total
                </td>
                <td className="border border-gray-300 px-4 py-2 text-center font-bold">
                  {calculateColumnTotal("education")}
                </td>
                <td className="border border-gray-300 px-4 py-2 text-center font-bold">
                  {calculateColumnTotal("WASH")}
                </td>
                <td className="border border-gray-300 px-4 py-2 text-center font-bold">
                  {calculateColumnTotal("Health")}
                </td>
                <td className="border border-gray-300 px-4 py-2 text-center font-bold">
                  {calculateColumnTotal("foodSecurity")}
                </td>
                <td className="border border-gray-300 px-4 py-2 text-center font-bold">
                  {calculateColumnTotal("livelihoods")}
                </td>
                <td className="border border-gray-300 px-4 py-2 text-center font-bold">
                  {calculateColumnTotal("emergencies")}
                </td>
                <td className="border border-gray-300 px-4 py-2 text-center font-bold">
                  {data.reduce(
                    (total, row) =>
                      total +
                      calculateGrandTotal(
                        row
                      ) /* Calculate grand total of all rows */,
                    0
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Card>
    </div>
  );
};

export default Scope;
